/* You can find the original at https://github.com/edwardtufte/tufte-css */

/* Import ET Book styles
   adapted from https://github.com/edwardtufte/et-book/blob/gh-pages/et-book.css */

   @charset "UTF-8";

   @font-face {
       font-family: "et-book";
       src: url("et-book/et-book-roman-line-figures/et-book-roman-line-figures.eot");
       src: url("et-book/et-book-roman-line-figures/et-book-roman-line-figures.eot?#iefix") format("embedded-opentype"),
           url("et-book/et-book-roman-line-figures/et-book-roman-line-figures.woff") format("woff"),
           url("et-book/et-book-roman-line-figures/et-book-roman-line-figures.ttf") format("truetype"),
           url("et-book/et-book-roman-line-figures/et-book-roman-line-figures.svg#etbookromanosf") format("svg");
       font-weight: normal;
       font-style: normal;
   }
   
   @font-face {
       font-family: "et-book";
       src: url("et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.eot");
       src: url("et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.eot?#iefix")
               format("embedded-opentype"),
           url("et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.woff")
               format("woff"),
           url("et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.ttf")
               format("truetype"),
           url("et-book/et-book-display-italic-old-style-figures/et-book-display-italic-old-style-figures.svg#etbookromanosf")
               format("svg");
       font-weight: normal;
       font-style: italic;
   }
   
   @font-face {
       font-family: "et-book";
       src: url("et-book/et-book-bold-line-figures/et-book-bold-line-figures.eot");
       src: url("et-book/et-book-bold-line-figures/et-book-bold-line-figures.eot?#iefix") format("embedded-opentype"),
           url("et-book/et-book-bold-line-figures/et-book-bold-line-figures.woff") format("woff"),
           url("et-book/et-book-bold-line-figures/et-book-bold-line-figures.ttf") format("truetype"),
           url("et-book/et-book-bold-line-figures/et-book-bold-line-figures.svg#etbookromanosf") format("svg");
       font-weight: bold;
       font-style: normal;
   }
   
   @font-face {
       font-family: "et-book-roman-old-style";
       src: url("et-book/et-book-roman-old-style-figures/et-book-roman-old-style-figures.eot");
       src: url("et-book/et-book-roman-old-style-figures/et-book-roman-old-style-figures.eot?#iefix")
               format("embedded-opentype"),
           url("et-book/et-book-roman-old-style-figures/et-book-roman-old-style-figures.woff") format("woff"),
           url("et-book/et-book-roman-old-style-figures/et-book-roman-old-style-figures.ttf") format("truetype"),
           url("et-book/et-book-roman-old-style-figures/et-book-roman-old-style-figures.svg#etbookromanosf") format("svg");
       font-weight: normal;
       font-style: normal;
   }
   
   $serif-fonts: et-book, "Noto Serif SC", Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif, "Noto Emoji";
   $sans-fonts: "Gill Sans", "Gill Sans MT", Calibri, sans-serif, "Noto Emoji";
   $mono-fonts: Consolas, "Liberation Mono", Menlo, Courier, monospace, "Noto Emoji";
   
   /* Define light and dark mode variables properly */
   $paper-color-light: #fffff8;
   $paper-color-bottom-light: #fef7f8;
   $text-color-light: #111;
   
   $paper-color-dark: #151515;
   $paper-color-bottom-dark: #1a1a1a;
   $text-color-dark: #ddd;
   
   /* Use light mode by default */
   $paper-color: $paper-color-light;
   $paper-color-bottom: $paper-color-bottom-light;
   $text-color: $text-color-light;
   
   /* Tufte CSS styles */
   html {
       font-size: 15px;
       /* Apply gradient to html element which spans the full viewport width */
       background: linear-gradient(to bottom, $paper-color, $paper-color-bottom);
       /* Ensure the html element takes up the full height */
       min-height: 100vh;
       /* For mobile devices - extend into safe area */
       padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
       /* Smoother font rendering */
       -webkit-font-smoothing: antialiased;
       -moz-osx-font-smoothing: grayscale;
       text-rendering: optimizeLegibility;
   }
   
   body {
       margin-left: auto;
       margin-right: auto;
       padding-left: 12.5%;
       padding-right: 5%;
       font-family: $serif-fonts;
       /* Remove gradient from body and make it transparent */
       background-color: transparent;
       color: $text-color;
       max-width: 1400px;
       margin: 0 auto;
       /* Ensure content fills available height */
       min-height: 100vh;
   }
   
   @media (prefers-color-scheme: dark) {
       html {
           background: linear-gradient(to bottom, $paper-color-dark, $paper-color-bottom-dark);
       }
       
       body {
           /* No background here - let html gradient show through */
           color: $text-color-dark;
       }
       
       a:link, a:visited {
           color: #9ecaff; /* Improved link visibility in dark mode */
       }
   }
   
   h1, h2, h3 {
       font-weight: 400;
       line-height: 1;
   }
   
   h1 {
       margin-top: 4rem;
       margin-bottom: 1.5rem;
       font-size: 3.2rem;
   }
   
   h2, h3 {
       font-style: italic;
       margin-bottom: 0;
   }
   
   h2 {
       margin-top: 2.1rem;
       font-size: 2.2rem;
   }
   
   h3 {
       font-size: 1.7rem;
       margin-top: 2rem;
   }
   
   .author, .date {
     font-size: 1.4rem;
     font-weight: 400;
     margin: 1rem auto 1rem 0;
     line-height: 1;
   }
   
   .subtitle {
       font-style: italic;
       margin-top: 1rem;
       margin-bottom: 1rem;
       font-size: 1.8rem;
       display: block;
       line-height: 1;
   }
   
   .numeral {
       font-family: et-book-roman-old-style;
   }
   
   .danger {
       color: red;
   }
   
   article {
       position: relative;
       padding: 5rem 0rem;
   }
   
   section {
       padding-top: 1rem;
       padding-bottom: 1rem;
   }
   
   .page-list .content-title {
       margin-top: 4.2rem;
       margin-bottom: 1.4rem;
   }
   
   .page-list .content-title:first-child {
       margin-top: 1.4rem;
   }
   
   p,
   ol,
   ul,
   dl {
       font-size: 1.4rem;
   }
   
   p {
       line-height: 1.6; /* Improved from 2rem fixed height to relative measurement */
       margin-top: 1.4rem;
       margin-bottom: 1.4rem;
       padding-right: 0;
       vertical-align: baseline;
       hyphens: auto;
       -webkit-hyphenate-limit-before: 3;
       -webkit-hyphenate-limit-after: 4;
       -ms-hyphenate-limit-chars: 10 3 4;
       hyphenate-limit-chars: 10 3 4;
   }
   
   /* Chapter Epigraphs */
   .epigraph {
       margin: 3em 0;
   }
   
   .epigraph > blockquote {
       margin-top: 3em;
       margin-bottom: 3em;
   }
   
   .epigraph > blockquote {
       font-style: italic;
   }
   
   .epigraph > blockquote > footer {
       font-style: normal;
   }
   
   .epigraph > blockquote > footer > cite {
       font-style: italic;
   }
   
   /* end chapter epigraphs styles */
   
   blockquote {
       font-size: 1.4rem;
   }
   
   blockquote p {
       width: 50%;
   }
   
   blockquote footer {
       width: 50%;
       font-size: 1.1rem;
       text-align: right !important;
   }
   
   ol,
   ul,
   dl {
       width: 45%;
       -webkit-padding-start: 5%;
       -webkit-padding-end: 5%;
   }
   
   li  ul {
       width: 100%;
   }
   
   li,
   dt, dd {
       padding: 0.5rem 0;
   }
   
   dt {
       font-weight: 700;
   }
   
   figure {
       padding: 0;
       border: 0;
       font-size: 100%;
       font: inherit;
       vertical-align: baseline;
       max-width: 55%;
       -webkit-margin-start: 0;
       -webkit-margin-end: 0;
       margin: 0 0 3em 0;
   }
   
   figcaption {
       float: right;
       clear: right;
       margin-right: -48%;
       margin-top: 0;
       margin-bottom: 0;
       font-size: 1.1rem;
       line-height: 1.6;
       vertical-align: baseline;
       position: relative;
       max-width: 40%;
   }
   
   figure.fullwidth figcaption {
       margin-right: 24%;
   }
   
   /* Links: replicate underline that clears descenders */
   a:link,
   a:visited {
       color: inherit;
   }
   
   /* Focus states for accessibility */
   a:focus, input:focus, button:focus {
       outline: 2px solid #0074d9;
       outline-offset: 2px;
   }
   
   /* Sidenotes, margin notes, figures, captions */
   img {
       max-width: 100%;
       height: auto; /* Ensure proper aspect ratio */
   }
   
   .sidenote,
   .marginnote {
       float: right;
       clear: right;
       margin-right: -60%;
       width: 50%;
       margin-top: 0;
       margin-bottom: 0;
       font-size: 1.1rem;
       line-height: 1.3;
       vertical-align: baseline;
       position: relative;
   }
   
   .table-caption {
       float: right;
       clear: right;
       margin-right: -60%;
       width: 50%;
       margin-top: 0;
       margin-bottom: 0;
       font-size: 1rem;
       line-height: 1.6;
   }
   
   .marginnote-ind,
   .sidenote-number {
       // font-family: et-book-roman-old-style, "Noto Emoji";
       position: relative;
       vertical-align: baseline;
       user-select: none;
   }
   
   // note indicator
   label.marginnote-ind,
   label.sidenote-number {
       font-size: 1rem;
       top: -0.5rem;
       left: 0.1rem;
   }
   
   // inside sidenote
   span.marginnote-ind,
   span.sidenote-number {
       font-size: 1.1rem;
   }
   
   p,
   footer,
   table,
   div.table-wrapper-small,
   div.supertable-wrapper > p,
   div.booktabs-wrapper {
       width: 55%;
   }
   
   div.fullwidth,
   table.fullwidth {
       width: 100%;
   }
   
   div.table-wrapper {
       overflow-x: scroll;
       font-family: "Trebuchet MS", "Gill Sans", "Gill Sans MT", sans-serif, "Noto Emoji";
   }
   
   /* Tablet breakpoint */
   @media screen and (max-width: 1024px) {
       body {
           padding-left: 8%;
           padding-right: 4%;
       }
       
       p, footer, table, div.table-wrapper-small, div.supertable-wrapper > p, div.booktabs-wrapper {
           width: 70%;
       }
       
       pre.code {
           width: 65%;
       }
       
       .sidenote, .marginnote {
           margin-right: -55%;
       }
   }
   
   /* Mobile breakpoint */
   @media screen and (max-width: 760px) {
       html {
           /* Extend into safe areas on mobile */
           padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
           font-size: 14px; /* Slightly smaller base font size for mobile */
       }
       
       body {
           padding-left: calc(5% + env(safe-area-inset-left));
           padding-right: calc(5% + env(safe-area-inset-right));
       }
       
       h1 {
           font-size: 2.5rem;
           margin-top: 3rem;
       }
       
       h2 {
           font-size: 1.8rem;
       }
       
       h3 {
           font-size: 1.5rem;
       }
       
       h2,
       h3,
       p,
       footer {
           width: 100%;
       }
       
       ul,
       ol,
       dl {
           width: 85%;
       }
       
       figure {
           max-width: 90%;
       }
       
       figcaption,
       figure.fullwidth figcaption {
           margin-right: 0%;
           max-width: none;
       }
       
       blockquote p,
       blockquote footer {
           width: 90%;
       }
       
       /* Improve spacing on mobile */
       article {
           padding: 3rem 0;
       }
       
       label.marginnote-ind {
           display: inline;
       }
       
       .sidenote,
       .marginnote {
           display: none;
       }
       
       .margin-toggle:checked + .sidenote,
       .margin-toggle:checked + .marginnote {
           display: block;
           float: left;
           left: 1rem;
           clear: both;
           width: 95%;
           margin: 1rem 2.5%;
           vertical-align: baseline;
           position: relative;
       }
       
       pre.code {
           width: 100%;
           font-size: 0.85rem;
           overflow-x: auto;
           -webkit-overflow-scrolling: touch;
           padding: 0;
       }
       
       .table-caption {
           display: block;
           float: right;
           clear: both;
           width: 98%;
           margin-top: 1rem;
           margin-bottom: 0.5rem;
           margin-left: 1%;
           margin-right: 1%;
           vertical-align: baseline;
           position: relative;
       }
       
       div.table-wrapper,
       table,
       table.booktabs {
           width: 85%;
       }
       
       div.table-wrapper {
           border-right: 1px solid #efefef;
       }
       
       img {
           width: 100%;
       }
       
       .video {
           width: 90%;
       }
   }
   
   .sans {
       font-family: $sans-fonts;
       letter-spacing: 0.03em;
   }
   
   code,
   .code,
   kbd {
       font-family: $mono-fonts;
       font-size: 1.125rem;
       line-height: 1.42;
   }
   
   h1 .code,
   h2 .code,
   h3 .code {
       font-size: 0.8em;
   }
   
   .marginnote .code,
   .sidenote .code {
       font-size: 1rem;
   }
   
   pre.code {
       font-size: 0.9rem;
       width: 52.5%;
       padding-left: 2.5%;
       overflow-x: scroll;
   }
   
   .fullwidth {
       max-width: 90%;
       clear: both;
   }
   
   .newthought {
       font-variant: small-caps;
       font-size: 1.2em;
   }
   
   .margin-toggle {
       cursor: pointer;
   }
   
   // accessibility feature: make label focus-able
   input.margin-toggle {
       position: absolute;
       outline: none;
       opacity: 0;
       width: 1px;
       height: 1px;
       margin-left: 5px;
       margin-top: 5px;
       z-index: -100;
   }
   
   label:has(+ input.margin-toggle:focus) {
     outline: medium auto currentColor;
     outline: medium auto invert;
     outline: 5px auto -webkit-focus-ring-color;
     outline-offset: -3px;
   }
   
   label.sidenote-number {
       display: inline;
   }
   
   label.marginnote-ind {
       display: none;
   }
   
   .video-container {
       width: 100%;
       margin-top: 1.4rem;
       margin-bottom: 1.4rem;
   }
   
   .video {
       width: 55%;
   }
   
   .video--16x9 {
       aspect-ratio: 16/9;
   }
   
   .video--4x3 {
       aspect-ratio: 4/3;
   }
   
   @media (prefers-reduced-motion: reduce) {
       /* Reduce motion for users who prefer it */
       * {
           transition: none !important;
           animation: none !important;
       }
   }
   
   /* Print styles */
   @media print {
       html {
           background: none !important;
       }
       
       body {
           margin: 0;
           padding: 0.5in;
           background: none !important;
           color: #000;
       }
       
       .sidenote, .marginnote {
           float: none;
           margin: 1rem 0;
           width: 100%;
           display: block;
           font-size: 0.9rem;
       }
       
       a {
           color: #000;
           text-decoration: underline;
       }
       
       a:after {
           content: " (" attr(href) ")";
           font-size: 0.8em;
       }
   }